.App {
  text-align: center;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #030303;
}

@keyframes colorChange {
  0% {
    background-color: #ffadad;
  }
  20% {
    background-color: #ffd6a5;
  }
  40% {
    background-color: #fdffb6;
  }
  60% {
    background-color: #caffbf;
  }
  80% {
    background-color: #9bf6ff;
  }
  100% {
    background-color: #a0c4ff;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  animation: colorChange 10s infinite alternate;
  /* Light Gray */

}

.page-footer {
  margin-top: auto;
  min-width: 100vw;
  background-color: #e0e0e0; /* Lighter Gray */
}
