/* styles.css */
  
  /* Container */
  .container {
    max-width: 600px; /* Limit the width of the form */
    background: white; /* White background for the form container */
    padding: 30px; /* Add padding inside the form container */
    margin: 50px auto; /* Center the form container and add top margin */
    border-radius: 10px; /* Rounded corners for the form container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow around the form container */
  }
  
  /* Form Heading */
  h2 {
    color: #333; /* Dark grey color for the heading */
    text-align: center; /* Center align the heading */
    margin-bottom: 30px; /* Add bottom margin to the heading */
  }
  
  /* Form Labels */
  label {
    font-weight: bold; /* Make labels bold */
    color: #666; /* Medium grey color for labels */
  }
  
  /* Form Controls */
  .form-control {
    border-radius: 5px; /* Rounded corners for input fields */
    border: 1px solid #ccc; /* Light grey border for input fields */
    padding: 10px; /* Add padding inside input fields */
    margin-bottom: 20px; /* Add bottom margin to input fields */
  }
  
  /* Form Controls Focus */
  .form-control:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue shadow on focus */
  }
  
  /* Submit Button */
  .btn-primary {
    background-color: #007bff; /* Blue background color for the submit button */
    border-color: #007bff; /* Blue border color for the submit button */
    padding: 10px 20px; /* Add padding inside the submit button */
    border-radius: 5px; /* Rounded corners for the submit button */
    width: 100%; /* Full width for the submit button */
    font-size: 16px; /* Larger font size for the submit button */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  /* Submit Button Hover */
  .btn-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
    border-color: #0056b3; /* Darker blue border on hover */
  }
  