/* Countdown */
.countdown {
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    color: white; /* White text for the countdown */
    font-size: 18px; /* Font size for the countdown */
    font-weight: bold; /* Bold text for the countdown */
    background-color: transpaent; /* Semi-transparent black background */
    border-radius: 5px; /* Rounded corners for the countdown */
    padding: 10px; /* Padding for the countdown */
    /* margin-top: 20px; Top margin for spacing */
  }
  
  .countdown-item {
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack interval and value vertically */
    align-items: center; /* Center items horizontally */
    margin: 0 10px; /* Add horizontal spacing between items */
  }
  
  .countdown-interval {
    font-size: 14px; /* Smaller font size for the interval */
    text-transform: capitalize; /* Capitalize the first letter of the interval */
  }
  
  .countdown-value {
    font-size: 24px; /* Larger font size for the value */
    margin-top: 5px; /* Add top margin to separate from the interval */
  }
  