  
  /* Full screen image container */
  .full-screen-image {
    margin-top: 1vh;
    margin-bottom: 1vh;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    background-position: center; /* Center the image */
    overflow: hidden;
  }
  
  /* Full screen image styling */
  .full-screen-image img {
    min-width: 95%;
    height: auto;
    object-fit: cover; /* Cover the entire container without distortion */
  }
  .def {
    margin: 1vw;
  }