.buttons{
display: flex;
justify-content: space-evenly;
align-self:center;
}
.countdown-wrapper{
    display: flex;
}
a{
    margin-left: 1vw;
}

img{
    height: 4vh;
}

